import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import LogIn from './LogIn';

export class StudentSection extends Component {
    render() {
        return (
            <div>
                <LogIn />

                <div>
                    <Row>
                        <Col xs={3}>test</Col>
                        <Col xs={9}>content</Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default StudentSection;
