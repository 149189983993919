import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import Iframe from 'react-iframe';
import ComingSoon from './ComingSoon';
import ContentCreator from './ContentCreator';
import Notebook from './Notebook';

export class Teacher extends Component {
    state = {
        activeIndex: 0,
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 0 });
                            }}
                        >
                            Content generator/Lesson creator
                        </div>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 1 });
                            }}
                        >
                            Student managements/links
                        </div>
                    </Col>
                    <Col xs={9} style={{ borderLeftWidth: 1, borderLeftColor: '#D3D3D3', borderLeftStyle: 'solid' }}>
                        {activeIndex == 0 && <ContentCreator />}
                        {activeIndex == 1 && <ComingSoon />}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Teacher;
