import React from 'react';
import './SearchField.css';
import SearchIcon from '@material-ui/icons/Search';

const SearchField = ({ onValueChanged, inputClassNames }) => {
    return (
        <div className="search-field-wrapper">
            <input
                type="text"
                onChange={(e) => {
                    onValueChanged(e.target.value);
                }}
                className={`search-field-input ${inputClassNames}`}
                placeholder="Search a Learning Tool ..."
            />
            {/* <i className="search-icon">
                <SearchIcon />
            </i> */}
        </div>
    );
};

export default SearchField;
