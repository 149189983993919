import logo from './logo.svg';
import './App.css';
import Homepage from './containers/Homepage';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { AlaiContainer } from './containers/AlaiContainer';

import ReactGA from 'react-ga';
const trackingId = 'UA-201066309-1'; // Replace with your Google Analytics tracking ID

function App() {
    ReactGA.initialize(trackingId);
    ReactGA.pageview('/');
    return (
        <div className="App">
            <Router>
                <Switch>
                    {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route> */}

                    <Route path="/aded/alai">
                        <AlaiContainer />
                    </Route>
                    <Route path="/">
                        <Homepage />
                    </Route>
                </Switch>
            </Router>
            {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
        </div>
    );
}

export default App;
