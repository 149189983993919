import React, { Component } from 'react';
import '../assets/css/components/login.css';
import { TextField, Button, Select, MenuItem } from '@material-ui/core';

class LogIn extends Component {
    state = {
        username: '',
        password: '',
        users: [
            {
                id: 0,
                name: 'PerL Student',
                username: 'student',
                password: 'perl',
                userType: 'student',
            },
            {
                id: 1,
                name: 'PerL Teacher',
                username: 'teacher',
                password: 'perl',
                userType: 'teacher',
            },
            {
                id: 2,
                name: 'PerL School',
                username: 'school',
                password: 'perl',
                userType: 'school',
            },
            {
                id: 3,
                name: 'PerL Solutions Provider',
                username: 'solutions-provider',
                password: 'perl',
                userType: 'sp',
            },
        ],
    };

    verifyUser() {
        const { username, password, users } = this.state;
        const selectedUser = users.find((user) => {
            return user.username == username && user.password == password;
        });

        return selectedUser;
    }

    componentDidMount() {
        // https://www.googleapis.com/customsearch/v1?key=AIzaSyC5MB_d4qqNzwElUVXY4r33SpJeDBoYww0&cx=017931382357043421780:uzn6zxe0r1o&q=computer
    }

    render() {
        return (
            <div className="log-in-container">
                <div className="log-in-textfield-container">
                    <TextField
                        className="log-in-textfield"
                        id="outlined-search"
                        label="Username"
                        type="search"
                        variant="outlined"
                        onChange={(e) => {
                            this.setState({ username: e.target.value });
                        }}
                    />
                </div>

                <div className="log-in-textfield-container">
                    <TextField
                        className="log-in-textfield"
                        id="outlined-search"
                        label="Password"
                        type="search"
                        variant="outlined"
                        onChange={(e) => {
                            this.setState({ password: e.target.value });
                        }}
                    />
                </div>

                <Button
                    className="log-in-button"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        this.props.handleSubmit(this.verifyUser());
                    }}
                >
                    Login
                </Button>
            </div>
        );
    }
}

export default LogIn;