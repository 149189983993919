import React, { Component } from 'react';
import Header from '../components/Header';
import { Container } from '@material-ui/core';

import Alai from '../components/aded/Alai';
import { Image } from 'react-bootstrap';

import ReactGA from 'react-ga';

export class AlaiContainer extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <div>
                <Header
                    navigationClicked={(activeTabIndex) => {
                        this.setState({ activeTabIndex });
                    }}
                    logoClicked={() => {
                        this.setState({ activeTabIndex: 0 });
                    }}
                    activeTabIndex={0}
                />

                <div style={{ marginTop: 20, marginBottom: 50 }}>
                    <Container>
                        <div style={{ maxWidth: 700, marginBottom: 30 }}>
                            <Image fluid src="/images/headings/alai.png" />
                        </div>
                        <Alai />
                    </Container>
                </div>
            </div>
        );
    }
}

export default AlaiContainer;
