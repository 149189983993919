import React, { Component } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { SubjectsData } from '../constants/SubjectsData';
import { CurriculumsData } from '../constants/CurriculumsData';
import '../assets/css/components/curiculums.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Iframe from 'react-iframe';
import CancelIcon from '@material-ui/icons/Cancel';

export class Curriculums extends Component {
    state = {
        subject: SubjectsData[0].value,
        subjects: SubjectsData,
        curriculums: CurriculumsData,
        activeCurriculum: CurriculumsData[0],
        activeUrl: '',
    };

    handleSubjectChange = (e) => {
        this.setState({ subject: e.target.value });
    };

    setActiveCurriculum = (data) => {
        this.setState({ activeCurriculum: data, subject: SubjectsData[0].value });
    };

    getContentsFilteredBySubject = () => {
        const { subject, activeCurriculum } = this.state;
        let contents = [];

        activeCurriculum.data.map((datum) => {
            if (subject == 'all' || subject == datum.subject) {
                contents.push(datum);
            }
        });

        return contents;
    };

    render() {
        const { subject, subjects, curriculums } = this.state;
        const contents = this.getContentsFilteredBySubject();

        return (
            <div className="learning-guide-content-container">
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Image fluid src="/images/headings/alg.jpg" />
                </div>

                <Container fluid>
                    <Row>
                        <Col md={1}>
                            <div className="category-selection-container">
                                {/* category selection here */}
                                {curriculums.map((datum, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => this.setActiveCurriculum(datum)}
                                            className="category-selection-button"
                                        >
                                            {datum.label}
                                        </div>
                                    );
                                })}
                            </div>
                        </Col>

                        <Col>
                            <div className="select-subject-container">
                                <span className="subject-selection-label">Subject: </span>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={subject}
                                    onChange={this.handleSubjectChange}
                                >
                                    {subjects.map((datum, index) => {
                                        return (
                                            <MenuItem key={index} MenuItem value={datum.value}>
                                                {datum.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>

                            {contents.length > 0 ? (
                                contents.map((datum, index) => {
                                    return (
                                        // <a href={datum.url} className="url-container" target="iframe_a">
                                        <div
                                            key={index}
                                            // onClick={() => {
                                            //     window.location.href = datum.url;
                                            // }}
                                            onClick={() => {
                                                this.setState({ activeUrl: datum.url });
                                            }}
                                            className="content-wrapper"
                                        >
                                            <p className="title">{datum.title}</p>
                                            <p className="thumbnail">{datum.thumbnail}</p>
                                            <p className="url">{datum.url}</p>
                                            <p>{datum.description}</p>
                                        </div>
                                        // </a>
                                    );
                                })
                            ) : (
                                <div>No contents found</div>
                            )}
                        </Col>

                        {this.state.activeUrl && (
                            <Col>
                                <div
                                    onClick={() => {
                                        this.setState({ activeUrl: '' });
                                    }}
                                >
                                    <CancelIcon />
                                </div>
                                <div className="curriculums-iframe-container">
                                    <Iframe
                                        url={this.state.activeUrl}
                                        width="100%"
                                        height="630px"
                                        frameBorder="0"
                                        id="myId"
                                        name="iframe_a"
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Curriculums;
