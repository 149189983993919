import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Col, Row, Image } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function AccountPopover({ signupButtonClicked, loginButtonClicked, user }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Fragment>
            <div
                aria-describedby={id}
                onClick={handleClick}
                style={{
                    // cursor: 'pointer',
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'left',
                    // height: '100%',
                    // flexDirection: 'column',
                    marginTop: 25,
                }}
            >
                {/* <Row>
                    <Col xs={3} style={{ paddingRight: 0, textAlign: 'right' }}>
                        <div style={{ width: 50, display: 'inline-block' }}>
                            <Image fluid src="/images/header/user.svg" />
                        </div>
                    </Col> */}
                {/* <Col xs={9}> */}
                {/* <div style={{ display: 'inline-block', marginLeft: 10 }}> */}
                <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                    Hi! {user ? `${user.name}!` : 'Login In Here!'}
                </div>
                {/* <div style={{ fontWeight: 'bold', color: '#6bb650' }}>PerL Access</div> */}
                {/* </div> */}
                {/* </Col> */}
                {/* </Row> */}
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.paper,
                }}
            >
                <div style={{ padding: 15, minWidth: 200 }}>
                    {!user && (
                        <div
                            onClick={() => {
                                loginButtonClicked();
                                handleClose();
                            }}
                            style={{
                                background: '#669a4d',
                                borderRadius: 20,
                                fontWeight: 900,
                                textAlign: 'center',
                                padding: 5,
                                color: '#FFFFFF',
                                cursor: 'pointer',
                            }}
                        >
                            Login
                        </div>
                    )}

                    {user && (
                        <div
                            onClick={() => {
                                handleClose();
                                localStorage.removeItem('user');
                                window.location.reload();
                            }}
                            style={{
                                background: '#669a4d',
                                borderRadius: 20,
                                fontWeight: 900,
                                textAlign: 'center',
                                padding: 5,
                                color: '#FFFFFF',
                                cursor: 'pointer',
                            }}
                        >
                            Logout
                        </div>
                    )}

                    <div className="label-description">New here?</div>
                    <div
                        onClick={() => {
                            signupButtonClicked();
                            handleClose();
                        }}
                        className="account-btn"
                    >
                        Join to Access
                    </div>

                    <div
                        style={{
                            borderBottomWidth: 1,
                            borderBottomColor: '#D3D3D3',
                            borderBottomStyle: 'solid',
                            width: '65%',
                            marginTop: 10,
                            marginBottom: 15,
                        }}
                    ></div>

                    <div className="label-description">Join as</div>
                    <div
                        onClick={() => {
                            signupButtonClicked();
                            handleClose();
                        }}
                        className="account-btn"
                    >
                        Student
                    </div>
                    <div
                        onClick={() => {
                            signupButtonClicked();
                            handleClose();
                        }}
                        className="account-btn"
                    >
                        Teacher
                    </div>
                    <div
                        onClick={() => {
                            signupButtonClicked();
                            handleClose();
                        }}
                        className="account-btn"
                    >
                        School
                    </div>
                    <div
                        onClick={() => {
                            signupButtonClicked();
                            handleClose();
                        }}
                        className="account-btn"
                    >
                        Solutions Provider
                    </div>
                </div>
            </Popover>
        </Fragment>
    );
}
