import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import ComingSoon from './ComingSoon';

export class School extends Component {
    state = {
        activeIndex: 0,
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div>
                <Row>
                    <Col xs={3}>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 0 });
                            }}
                        >
                            School management
                        </div>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 1 });
                            }}
                        >
                            Supplier management
                        </div>
                    </Col>
                    <Col xs={9} style={{ borderLeftWidth: 1, borderLeftColor: '#D3D3D3', borderLeftStyle: 'solid' }}>
                        {activeIndex == 0 && <ComingSoon />}
                        {activeIndex == 1 && <ComingSoon />}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default School;
