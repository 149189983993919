import React, { Component } from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import Iframe from 'react-iframe';

export class Ect extends Component {
    state = {
        activeIndex: 0,
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div>
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Image fluid src="/images/headings/ect.jpg" />
                </div>
                <Row>
                    <Col xs={2}>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 0 });
                            }}
                        >
                            Multiple Intelligence Test
                        </div>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 1 });
                            }}
                        >
                            Learning Style Test
                        </div>
                    </Col>
                    <Col xs={10} style={{ borderLeftWidth: 1, borderLeftColor: '#D3D3D3', borderLeftStyle: 'solid' }}>
                        {activeIndex == 0 && (
                            <Iframe
                                url={'https://personalitymax.com/personality-test/'}
                                width="100%"
                                height="1250px"
                                frameBorder="0"
                                id="myId"
                            ></Iframe>
                        )}
                        {activeIndex == 1 && (
                            <Iframe
                                url={'https://personalitymax.com/personality-test/'}
                                width="100%"
                                height="1250px"
                                frameBorder="0"
                                id="myId"
                            ></Iframe>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Ect;
