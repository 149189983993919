export const CurriculumsData = [
    {
        category: 'g1',
        label: 'Grade 1',
        data: [
            {
                title: `MrNussbaum.com – Interactive educational games and activities`,
                thumbnail: `image thumbnail`,
                url: 'https://www.mrnussbaum.com/',
                description: `Featuring over 3,500 content pages, MrNussbaum.com is one of the most popular destinations on the internet for parents, homeschoolers, teachers, and students. MrNussbaum.com was created by Greg Nussbaum, a Virginia public school teacher with experience teaching 2nd, 3rd, 4th, 5th, and 6th grade classrooms.In this unit, learners are tasked to give oral interpretations of stories, identify and differentiate sentences and non-sentences, and read high frequency words.`,
                subject: 'english',
            },
        ],
    },
    {
        category: 'g2',
        label: 'Grade 2',
        data: [],
    },
];
