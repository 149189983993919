import React, { Component } from 'react';
import ItemCarousel from './ItemCarousel';
import SearchField from './SearchField';
import './Alai.css';
import { CircularProgress } from '@material-ui/core';

export class Alai extends Component {
    state = {
        data: [],
        filteredData: [],
        isLoading: true,
    };

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        let newAlaiData = [];

        const sheetID = '1IDi1F28Rs41IOEKL7lHOypRI3Cx_0KWGLkLdSboFrWk';
        const key = 'AIzaSyAcW7tJtFjK4vMNKZ1p2aNPrXrkfCcE7jg';
        const endpoint = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}?key=${key}`;

        await fetch(endpoint)
            .then((response) => response.json())
            .then((data) => {
                data.sheets.forEach((s) => {
                    const sheetName = s.properties.title;
                    const API = `https://sheets.googleapis.com/v4/spreadsheets/${sheetID}/values:batchGet?ranges=${sheetName}&majorDimension=ROWS&key=${key}`;
                    fetch(API)
                        .then((response) => response.json())
                        .then(async (data) => {
                            let rows = data.valueRanges[0].values;
                            if (sheetName == 'groups') {
                                let groupFields = [];
                                await rows.forEach((row, index) => {
                                    let newGroup = {};
                                    if (index > 0) {
                                        groupFields.forEach((field, index) => {
                                            newGroup[field] = row[index];
                                        });
                                    } else {
                                        groupFields = row;
                                        return;
                                    }

                                    newGroup.contents = [];
                                    newAlaiData.push(newGroup);
                                });
                            }
                            if (sheetName == 'apps') {
                                await newAlaiData.map(async (data) => {
                                    let contentFields = [];
                                    await rows.forEach((row, index) => {
                                        let newGroup = {};
                                        if (index > 0) {
                                            contentFields.forEach((field, index) => {
                                                newGroup[field] = row[index];
                                            });
                                        } else {
                                            contentFields = row;
                                            return;
                                        }

                                        if (String(newGroup.group_id) == String(data.group_id)) {
                                            data.contents.push(newGroup);
                                        }
                                    });
                                });
                            }

                            this.setState({ data: newAlaiData, filteredData: newAlaiData, isLoading: false });
                        });
                });
            });
    };

    applyFilter = async (value) => {
        const { data } = this.state;
        let filteredData = [];

        if (!value) {
            return this.setState({ filteredData: data });
        }

        const clonedData = JSON.parse(JSON.stringify(data));
        await clonedData.forEach((datum) => {
            let contents = [];
            datum.contents.forEach((content) => {
                if (
                    content.app_name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                    content.description.toLowerCase().indexOf(value.toLowerCase()) > -1
                ) {
                    contents.push(content);
                }
                return;
            });

            datum.contents = contents;

            filteredData.push(datum);
        });

        this.setState({ filteredData });
    };

    render() {
        const { filteredData, isLoading } = this.state;

        return !isLoading ? (
            <div>
                {filteredData ? (
                    <div>
                        <div className="search-field-container">
                            <SearchField
                                onValueChanged={(value) => {
                                    this.applyFilter(value);
                                }}
                            />
                        </div>

                        <div className="divider" />

                        <div>
                            {filteredData.map((data) => {
                                return (
                                    <div>
                                        {data.contents.length > 0 ? (
                                            <div className="apps-group-label">{data.group_name}</div>
                                        ) : null}

                                        <div className="apps-group-container">
                                            <ItemCarousel data={data.contents} />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        ) : (
            <div style={{ flex: 1, alignContent: 'center', textAlign: 'center' }} className="loading-container">
                <CircularProgress />
            </div>
        );
    }
}

export default Alai;
