import React, { Component, Fragment } from 'react';
import { Container } from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Header from '../components/Header';
import Search from '../components/Search';
import Curriculums from '../components/Curriculums';
import Notebook from '../components/Notebook';
import ContentCreator from '../components/ContentCreator';
import Assessment from '../components/Assessment';
import LogIn from '../components/LogIn';

// import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@material-ui/core';
import { Image, Modal } from 'react-bootstrap';

import Carousel from 'react-bootstrap/Carousel';
import StudentSection from '../components/StudentSection';
import Iframe from 'react-iframe';
import Compas from '../components/Compas';
import Hol from '../components/Hol';
import Student from '../components/Student';
import Teacher from '../components/Teacher';
import School from '../components/School';
import SchoolSolutionsProvider from '../components/SchoolSolutionsProvider';
import Ect from '../components/Ect';
import Alai from '../components/aded/Alai';

export class Homepage extends Component {
    state = {
        activeTabIndex: 0,
        isModalOpen: false,
        imagesArr: [
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-0.png`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-1.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-2.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-3.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-4.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-7.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-8.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-9.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-10.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-11.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-14.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-19.jpg`} fluid />,
            },
            {
                image: <Image src={`${process.env.PUBLIC_URL}/images/perl/slide-20.jpg`} fluid />,
            },
        ],
        user: null,
    };

    componentDidMount() {
        this.setState({ user: JSON.parse(localStorage.getItem('user')) });

        let sParam = 'active';
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');

        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                this.setState({ activeTabIndex: parseInt(sParameterName[1]) });
            }
        }
    }

    handleChange = (event, newValue) => {
        this.setState({ activeTabIndex: newValue });
    };

    openLogInModal = (id) => {
        this.setState({ isModalOpen: true });
    };

    render() {
        const { activeTabIndex } = this.state;
        const demoMode = false;

        function Item(props) {
            return (
                <Paper>
                    <p>{props.item.image}</p>
                </Paper>
            );
        }

        return (
            <div>
                <Header
                    navigationClicked={(activeTabIndex) => {
                        this.setState({ activeTabIndex });
                    }}
                    logoClicked={() => {
                        this.setState({ activeTabIndex: 0 });
                    }}
                    activeTabIndex={activeTabIndex}
                />

                <div style={{ marginTop: 20, marginBottom: 50 }}>
                    <Container>
                        {!activeTabIndex && (
                            <Container fluid>
                                <Carousel interval="5000" pause="hover">
                                    {this.state.imagesArr.map((image) => {
                                        return <Carousel.Item>{image.image}</Carousel.Item>;
                                    })}
                                </Carousel>
                            </Container>
                        )}

                        {activeTabIndex == 4 ? (
                            <Fragment>
                                <Alai />
                            </Fragment>
                        ) : demoMode ? (
                            <Fragment>
                                {activeTabIndex == 1 && !this.state.user ? (
                                    <div>Please login</div>
                                ) : activeTabIndex == 1 ? (
                                    this.state.user.userType == 'student' ? (
                                        <Student />
                                    ) : this.state.user.userType == 'teacher' ? (
                                        <Teacher />
                                    ) : this.state.user.userType == 'school' ? (
                                        <School />
                                    ) : this.state.user.userType == 'sp' ? (
                                        <SchoolSolutionsProvider />
                                    ) : null
                                ) : null}

                                {activeTabIndex == 2 && <Search />}

                                {activeTabIndex == 3 && <Curriculums />}

                                {activeTabIndex == 5 && <Compas />}

                                {activeTabIndex == 6 && <Hol />}

                                {activeTabIndex == 7 && <Ect />}

                                {activeTabIndex == 8 && (
                                    <Fragment>
                                        <div style={{ marginBottom: 15, textAlign: 'center' }}>
                                            <Image fluid src="/images/headings/program.jpg" />
                                            <Image fluid src="/images/headings/coming-soon.jpg" />
                                        </div>
                                    </Fragment>
                                )}

                                {activeTabIndex == 9 && (
                                    <Fragment>
                                        <div style={{ marginBottom: 15, textAlign: 'center' }}>
                                            <Image fluid src="/images/headings/reports.jpg" />
                                            <Image fluid src="/images/headings/coming-soon.jpg" />
                                        </div>
                                    </Fragment>
                                )}

                                {activeTabIndex == 10 && (
                                    <Fragment>
                                        <div style={{ marginBottom: 15, textAlign: 'center' }}>
                                            <Image fluid src="/images/headings/structures.jpg" />
                                            <Image fluid src="/images/headings/coming-soon.jpg" />
                                        </div>
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: 20, fontWeight: 900, color: '#6eb44f', marginBottom: 5 }}>
                                    Interested with PerL?
                                </div>
                                <div>Book a session with us.</div>
                                <div>Let's create the perfect learning mix, together.</div>
                                <a href="mailto:perl@dclaph.com">perl@dclaph.com</a>
                                <p>
                                    <a href="tel:+9173222596">+639173222596</a>
                                </p>
                            </div>
                        )}
                    </Container>
                    {/* <Iframe
                        url="https://personalitymax.com/personality-test/"
                        width="100%"
                        height="2650px"
                        frameBorder="0"
                        id="myId"
                        // className="myClassname"
                        // display="initial"
                        // position="relative"
                    /> */}
                </div>
                {/* <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange} aria-label="simple tabs example">
                        <Tab label="Advanced Learning Search" />
                        <Tab label="Assessment page" />
                        <Tab label="Learning guide" />
                        <Tab label="Student corner" />
                        <Tab label="Teacher tools" />
                    </Tabs>
                </AppBar> */}

                {/* <div style={{ marginTop: 30 }}>
                    {!activeTabIndex && (
                        <Container fluid>
                            <Carousel interval="5000" pause="hover">
                                {this.state.imagesArr.map((image) => {
                                    return <Carousel.Item>{image.image}</Carousel.Item>;
                                })}
                            </Carousel>
                        </Container>
                    )}

                    {activeTabIndex === 1 && }
                    {activeTabIndex === 2 && <Assessment />}
                    {activeTabIndex === 3 && <Curriculums />}
                    {activeTabIndex === 4 && <Notebook />}
                    {activeTabIndex === 5 && <ContentCreator />}

                    <StudentSection />
                </div>

                <Modal
                    show={this.state.isModalOpen}
                    onHide={() => this.setState({ isModalOpen: false })}
                    centered={true}
                    size="md"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Register</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <LogIn handleSubmit={() => this.setState({ isModalOpen: false })} />
                    </Modal.Body>
                </Modal>

                <Container fluid>
                    <p style={{ fontSize: 12, marginTop: 20 }}>
                        *For best experience, use a desktop browser such as Google Chrome or Mozilla Firefox.
                    </p>

                    <Button
                        style={{
                            borderRadius: 10,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'gray',
                            padding: 5,
                        }}
                        onClick={() => this.setState({ isModalOpen: true })}
                        color="primary"
                        variant="contained"
                    >
                        Register
                    </Button>
                </Container> */}

                {/* <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        background: 'green',
                        color: '#FFFFFF',
                        padding: 5,
                        textAlign: 'center',
                    }}
                >
                    This a Beta site of your PerL (Perfect Learning) experience! We are building the educational
                    ecosystem here!
                </div> */}

                <Container fluid>
                    <p style={{ fontSize: 12, marginTop: 20, position: 'fixed', bottom: 0 }}>
                        *For best experience, use a desktop browser such as Google Chrome or Mozilla Firefox.
                    </p>
                </Container>
            </div>
        );
    }
}

export default Homepage;
