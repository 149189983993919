import React, { Component } from 'react';
import "../assets/css/components/contentcreator.css"

import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Iframe from 'react-iframe';
import { MenuItem, Select, Step, StepLabel, Stepper, TextField } from '@material-ui/core';

import Button from '@material-ui/core/Button';

export class ContentCreator extends Component {
    state = {
        isEditMode: true,
        data: [],
        activeStep: 0,
    };

    componentDidMount() {
        this.getContents();
    }

    getContents = () => {
        axios
            .get(
                'https://sheets.googleapis.com/v4/spreadsheets/1gbkIMXgCVzhbZCApQ_sv3HdpvTAadvdpKNxxdjp__ig/values/aaaa?key=AIzaSyAcW7tJtFjK4vMNKZ1p2aNPrXrkfCcE7jg'
            )
            .then((res) => {
                this.setContentData(res.data.values);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    setContentData = (data) => {
        let dataArr = data;
        let newData = [];

        dataArr.forEach((datum, index) => {
            if (index > 0) {
                newData.push(datum);
            }
        });

        this.setState({ data: dataArr });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        });
    };

    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1,
        });
    };

    render() {
        const AntSwitch = withStyles((theme) => ({
            root: {
                width: 28,
                height: 16,
                padding: 0,
                display: 'flex',
            },
            switchBase: {
                padding: 2,
                color: theme.palette.grey[500],
                '&$checked': {
                    transform: 'translateX(12px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    },
                },
            },
            thumb: {
                width: 12,
                height: 12,
                boxShadow: 'none',
            },
            track: {
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: theme.palette.common.white,
            },
            checked: {},
        }))(Switch);

        const { data, isEditMode, activeStep } = this.state;
        let latestData = data[data.length - 1];
        let editUrl = latestData ? latestData[latestData.length - 1] : '';
        let previewUrl = editUrl ? editUrl.replace('edit', 'preview') : '';
        const stepContents = ['Step 1: Input', 'Step 2: Tags', 'Step 3: Review'];

        return (
            <div>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {stepContents.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && (
                    <Iframe
                        url="https://docs.google.com/forms/d/e/1FAIpQLScCcjnTi45nLDEvoFAOT4qMNAW-_mVM2M5TjHpxEWPPMsYEkw/viewform?embedded=true"
                        width="100%"
                        height="2650px"
                        frameBorder="0"
                        id="myId"
                        // className="myClassname"
                        // display="initial"
                        // position="relative"
                    />
                )}

                {activeStep === 1 && (
                    <div className="content-information-container">
                        <div className="content-infomation-wrapper">
                            <div className="content-infomation-label">Grade Level</div>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={'Science'}
                                className="content-information-select-button"
                                // onChange={this.handleSubjectChange}
                            >
                                <MenuItem value={'Science'}>Grade 1</MenuItem>
                            </Select>
                        </div>

                        <div className="content-infomation-wrapper">
                            <div className="content-infomation-label">Subject</div>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={'Science'}
                                className="content-information-select-button"
                                // onChange={this.handleSubjectChange}
                            >
                                <MenuItem value={'Science'}>Science</MenuItem>
                            </Select>
                        </div>

                        <div className="content-infomation-wrapper">
                            <div className="content-infomation-label">MELC</div>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={'Science'}
                                className="content-information-select-button"
                                // onChange={this.handleSubjectChange}
                            >
                                <MenuItem value={'Science'}>274-276</MenuItem>
                            </Select>
                        </div>
                        <TextField
                            id="standard-basic"
                            label="Tags"
                            className="content-information-textfield"
                            onChange={(e) => {
                                // this.setState({ searchInput: e.target.value });
                            }}
                        />
                    </div>
                )}

                {activeStep === 2 && (
                    <div className="google-slides-container">
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Preview</Grid>
                                <Grid item>
                                    <AntSwitch
                                        checked={this.state.isEditMode}
                                        onChange={this.handleChange}
                                        name="isEditMode"
                                    />
                                </Grid>
                                <Grid item>Edit</Grid>
                            </Grid>
                        </Typography>
                        {/* Step 3  slide */}
                        <Iframe
                            url={isEditMode ? editUrl : previewUrl}
                            width="100%"
                            height="1080px"
                            frameBorder="0"
                            id="myId"
                            // className="myClassname"
                            // display="initial"
                            // position="relative"
                        />
                    </div>
                )}

                <div className="stepper-button-container">
                    <Button disabled={activeStep === 0} onClick={this.handleBack}>
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        disabled={activeStep === stepContents.length - 1}
                        color="primary"
                        onClick={this.handleNext}
                    >
                        Next
                    </Button>
                </div>
            </div>
        );
    }
}

export default ContentCreator;
