export const SubjectsData = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Science',
        value: 'science',
    },
    {
        label: 'English',
        value: 'english',
    },
    {
        label: 'Math',
        value: 'math',
    },
    {
        label: 'Araling Panlipunan',
        value: 'ap',
    },
];
