import React, { Component, Fragment } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Iframe from 'react-iframe';

export class HOL extends Component {
    state = {
        activeIndex: 2,
        forms: [
            {
                id: 0,

                label: 'Learner Experience',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLScY3fvoNaL7igD2GBy06xNkykuD5zI7z3v2IqD5Bn6SgIVbfA/viewform?embedded=true',
            },
            {
                id: 1,
                label: 'Teaching Force',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLSfGNYcEcDPCdkcyMG6fsru9mcuXBGyjfljlx0DZFLHBwh0SPg/viewform?embedded=true',
            },
            {
                id: 2,
                label: 'PerL Score',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLScboE7cwiEyXvC0D-7ERwwrp9yUf1T2loI_ZZTtet6wZ9_slw/viewform?embedded=true',
            },
        ],
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div>
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Image fluid src="/images/headings/hol.jpg" />
                </div>
                <Row>
                    <Col xs={3}>
                        {this.state.forms.map((form) => {
                            return (
                                <Fragment>
                                    <div
                                        className="category-select-container"
                                        onClick={() => {
                                            this.setState({ activeIndex: form.id });
                                        }}
                                    >
                                        {form.label}
                                    </div>
                                </Fragment>
                            );
                        })}
                    </Col>
                    <Col xs={9} style={{ borderLeftWidth: 1, borderLeftColor: '#D3D3D3', borderLeftStyle: 'solid' }}>
                        <Iframe
                            url={this.state.forms[activeIndex].url}
                            width="100%"
                            height="1250px"
                            frameBorder="0"
                            id="myId"
                        ></Iframe>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default HOL;
