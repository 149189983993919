import React, { Component, Fragment } from 'react';
import { Image, Row, Col, Modal, Container } from 'react-bootstrap';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import './Header.css';
import AccountPopover from './header/AccountPopover';
import NavPopover from './header/NavPopover';
import LogIn from './LogIn';

import { withRouter } from 'react-router-dom';

export class Header extends Component {
    state = {
        navs: [
            {
                id: 1,
                label: 'ADVANCED LEARNING SEARCH',
                color: '#70B447',
                icon: '/images/learning_search.png',
            },
            {
                id: 2,
                label: 'ASSESSMENT PAGE',
                color: '#47B46B',
                icon: '/images/assessment.png',
            },
            {
                id: 3,
                label: 'LEARNING GUIDE',
                color: '#C0C03D',
                icon: '/images/learning_guide.png',
            },
            {
                id: 4,
                label: 'STUDENT CORNER',
                color: '#47B49B',
                icon: '/images/student_corner.png',
            },
            {
                id: 5,
                label: 'TEACHER TOOLS',
                color: '#4EC556',
                icon: '/images/teacher_tools.png',
            },
        ],

        anchorEl: null,
        isSignupInfoModalOpen: false,
        isLoginModalOpen: false,
        user: null,
    };

    componentDidMount() {
        this.setState({ user: JSON.parse(localStorage.getItem('user')) });
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { navs } = this.state;

        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col xs={4}>
                            <div
                                onClick={() => {
                                    this.props.history.push('/');
                                    // this.props.logoClicked();
                                }}
                                className="logo-container"
                            >
                                <Image fluid src="/images/header/logo-horizontal.png" />
                            </div>
                        </Col>
                        <Col xs={4}></Col>
                        <Col xs={4}>
                            <AccountPopover
                                signupButtonClicked={() => {
                                    this.setState({ isSignupInfoModalOpen: true });
                                }}
                                loginButtonClicked={() => {
                                    this.setState({ isLoginModalOpen: true });
                                }}
                                user={this.state.user}
                            ></AccountPopover>
                        </Col>
                    </Row>
                </Container>

                <div style={{ background: '#3f3f3f', color: 'white', textAlign: 'center', position: 'relative' }}>
                    <Row>
                        <Col xs={2}>
                            <div
                                className="nav-container"
                                onClick={() => {
                                    this.props.navigationClicked(0);
                                }}
                            >
                                <div className="navigation-menu-image-container">
                                    <Image fluid src="/images/icons/introv.png" />
                                </div>
                                <div className="navigation-menu-label">IntROV</div>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <NavPopover
                                label={
                                    <div className="nav-container">
                                        <div className="navigation-menu-image-container">
                                            <Image fluid src="/images/icons/aded.png" />
                                        </div>
                                        <div className="navigation-menu-label">AdEd</div>
                                    </div>
                                }
                            >
                                <div className="nav-popover-container">
                                    <div style={{ fontWeight: 'bold' }}>
                                        <div style={{ width: 30, display: 'inline-block', marginRight: 5 }}>
                                            <Image fluid src="/images/icons/aded.png" />
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            Experience AdEd (Advanced Education) here!
                                            <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                Multitude of learning tools that takes education to the next level.
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#D3D3D3',
                                            borderBottomStyle: 'solid',
                                            width: '65%',
                                            marginTop: 10,
                                            marginBottom: 15,
                                        }}
                                    ></div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(2);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/aded/1.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>ALEx</div>
                                            <div className="nav-label-submenu-description">
                                                (Advanced Learning Explorer)
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(3);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/aded/2.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>ALG</div>
                                            <div className="nav-label-submenu-description">
                                                (Advanced Learning Guide)
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.history.push('/aded/alai');
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/aded/3.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>ALAi</div>
                                            <div className="nav-label-submenu-description">
                                                (Advanced Learning Aide)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavPopover>
                        </Col>
                        <Col xs={2}>
                            <NavPopover
                                label={
                                    <div className="nav-container">
                                        <div className="navigation-menu-image-container">
                                            <Image fluid src="/images/icons/aded.png" />
                                        </div>
                                        <div className="navigation-menu-label">AsEs</div>
                                    </div>
                                }
                            >
                                <div className="nav-popover-container">
                                    <div style={{ fontWeight: 'bold' }}>
                                        <div style={{ width: 30, display: 'inline-block', marginRight: 5 }}>
                                            <Image fluid src="/images/icons/ases.png" />
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            Conduct AsEs (Assessments of Essentials) here.
                                            <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                Consolidated gauge tests that fuels improvement on every aspect.
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#D3D3D3',
                                            borderBottomStyle: 'solid',
                                            width: '65%',
                                            marginTop: 10,
                                            marginBottom: 15,
                                        }}
                                    ></div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(5);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/ases/1.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>CompAs</div>
                                            <div className="nav-label-submenu-description">
                                                (Comprehensive Assessment)
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(6);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 70, marginLeft: -10 }}>
                                            <Image fluid src="/images/icons/ases/2.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>HOL</div>
                                            <div className="nav-label-submenu-description">(Health Of Learning)</div>
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(7);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/ases/3.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>ECT</div>
                                            <div className="nav-label-submenu-description">
                                                (External Competency Tests)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavPopover>
                        </Col>
                        <Col xs={3}>
                            <NavPopover
                                label={
                                    <div className="nav-container">
                                        <div className="navigation-menu-image-container">
                                            <Image fluid src="/images/icons/progres.png" />
                                        </div>
                                        <div className="navigation-menu-label">ProgReS</div>
                                    </div>
                                }
                            >
                                <div className="nav-popover-container">
                                    <div style={{ fontWeight: 'bold' }}>
                                        <div style={{ width: 30, display: 'inline-block', marginRight: 5 }}>
                                            <Image fluid src="/images/icons/progres.png" />
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            Check your ProgReS (Programs, reports, and structures) here.
                                            <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                An intelligent learning dashboard that pictures the ecosystem health.
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            borderBottomWidth: 1,
                                            borderBottomColor: '#D3D3D3',
                                            borderBottomStyle: 'solid',
                                            width: '65%',
                                            marginTop: 10,
                                            marginBottom: 15,
                                        }}
                                    ></div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(8);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/progres/1.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>Progress</div>
                                            {/* <div className="nav-label-submenu-description">
                                                (Comprehensive Assessment)
                                            </div> */}
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(9);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/progres/2.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>Reports</div>
                                            {/* <div className="nav-label-submenu-description">(Health Of Learning)</div> */}
                                        </div>
                                    </div>
                                    <div
                                        className="nav-label-container"
                                        onClick={() => {
                                            this.props.navigationClicked(10);
                                        }}
                                    >
                                        <div style={{ display: 'inline-block', width: 50 }}>
                                            <Image fluid src="/images/icons/progres/3.png" />
                                        </div>
                                        <div style={{ display: 'inline-block', marginLeft: 15 }}>
                                            <div style={{ fontWeight: 700 }}>Structures</div>
                                            {/* <div className="nav-label-submenu-description">
                                                (External Competency Tests)
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </NavPopover>
                        </Col>
                        <Col xs={3} style={{ background: '#262626' }}>
                            <div
                                className="nav-container"
                                onClick={() => {
                                    this.props.navigationClicked(1);
                                }}
                            >
                                <div className="navigation-menu-image-container">
                                    <Image fluid src="/images/icons/user.svg" />
                                </div>
                                <div className="navigation-menu-label">Perl Access</div>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Signup Modal */}
                <Modal
                    show={this.state.isSignupInfoModalOpen}
                    onHide={() => this.setState({ isSignupInfoModalOpen: false })}
                    centered={true}
                    size="md"
                    dialogClassName="signup-modal"
                >
                    <Modal.Header>
                        <div style={{ fontWeight: 700, fontSize: 20 }}>YOUR PERL ACCESS IS HERE</div>
                        <div style={{ fontSize: 13 }}>PerL Access provides varying tools depending on user type.</div>
                        <div style={{ fontSize: 13 }}>
                            Imagine your social media accounts retrofitted for your learning needs.
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col xs={6}>
                                <div>
                                    <div className="usertype-header">Student</div>
                                    <ul className="usertype-list">
                                        <li>Personal Notes</li>
                                        <li>Learning Management</li>
                                    </ul>
                                    <div
                                        className="signup-modal-btn"
                                        onClick={() => {
                                            this.setState({ isSignupInfoModalOpen: false });
                                        }}
                                    >
                                        Student Signup
                                    </div>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    <div className="usertype-header">School</div>
                                    <ul className="usertype-list">
                                        <li>School Management</li>
                                        <li>Supplier Management</li>
                                    </ul>
                                    <div
                                        className="signup-modal-btn"
                                        onClick={() => {
                                            this.setState({ isSignupInfoModalOpen: false });
                                        }}
                                    >
                                        School Signup
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div>
                                    <div className="usertype-header">Teacher</div>
                                    <ul className="usertype-list">
                                        <li>Content Generator/Lesson Creator</li>
                                        <li>Student managements/Links</li>
                                    </ul>
                                    <div
                                        className="signup-modal-btn"
                                        onClick={() => {
                                            this.setState({ isSignupInfoModalOpen: false });
                                        }}
                                    >
                                        Teacher Signup
                                    </div>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    <div className="usertype-header">Solutions Provider</div>
                                    <ul className="usertype-list">
                                        <li>School Directory</li>
                                        <li>Solution Category</li>
                                    </ul>
                                    <div
                                        className="signup-modal-btn"
                                        onClick={() => {
                                            this.setState({ isSignupInfoModalOpen: false });
                                        }}
                                    >
                                        Solutions Provider Signup
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                {/* Login */}
                <Modal
                    show={this.state.isLoginModalOpen}
                    onHide={() => this.setState({ isLoginModalOpen: false })}
                    centered={true}
                    size="md"
                >
                    <Modal.Body>
                        <LogIn
                            handleSubmit={(selectedUser) => {
                                if (selectedUser) {
                                    localStorage.setItem('user', JSON.stringify(selectedUser));
                                    window.location.reload();
                                }
                            }}
                        />
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}

export default withRouter(Header);
