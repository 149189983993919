import React from 'react';
import { Image } from 'react-bootstrap';

const ComingSoon = () => {
    return (
        <div>
            <div style={{ marginBottom: 15, textAlign: 'center' }}>
                <Image fluid src="/images/headings/coming-soon.jpg" />
            </div>
        </div>
    );
};

export default ComingSoon;
