import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { Select, MenuItem } from '@material-ui/core';

export class Assessment extends Component {
    state = {
        forms: [
            {
                id: 0,
                label: 'HoL: Teacher Force',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLSfGNYcEcDPCdkcyMG6fsru9mcuXBGyjfljlx0DZFLHBwh0SPg/viewform?embedded=true',
            },
            {
                id: 1,
                label: 'HoL: Perfect Learning Score',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLScboE7cwiEyXvC0D-7ERwwrp9yUf1T2loI_ZZTtet6wZ9_slw/viewform?embedded=true',
            },
            {
                id: 2,
                label: 'HoL: Learner Experience',
                url:
                    'https://docs.google.com/forms/d/e/1FAIpQLScY3fvoNaL7igD2GBy06xNkykuD5zI7z3v2IqD5Bn6SgIVbfA/viewform?embedded=true',
            },
            {
                id: 3,
                label: 'AsEs: P1: WrUp',
                url:
                    'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdufilqVkVqnAN9apC_CEBVpHmZ43CKK21s3mbrFWXTMVSwlg/formResponse?embedded=true',
            },
            {
                id: 4,
                label: 'AsEs: P1: ConVo',
                url:
                    'https://docs.google.com/forms/u/0/d/e/1FAIpQLSf0F6S_Mdadpg3JT1bhKIEOe-z3wWchxGlg9IqZ9KUswzWCfQ/formResponse?embedded=true',
            },
        ],
        activeId: 0,
    };

    render() {
        let activeForm = this.state.forms.find((form) => {
            return form.id === this.state.activeId;
        });
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <span>Select an assessment form: </span>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.activeId}
                        onChange={(e) => {
                            this.setState({ activeId: e.target.value });
                        }}
                    >
                        {this.state.forms.map((datum, index) => {
                            return (
                                <MenuItem key={index} MenuItem value={datum.id}>
                                    {datum.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </div>

                <Iframe
                    url={activeForm.url}
                    width="100%"
                    height="700px"
                    display="initial"
                    position="relative"
                    frameBorder="0"
                />
            </div>
        );
    }
}

export default Assessment;
