import React, { Component } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { SearchData } from '../constants/SearchData';
import SearchIcon from '@material-ui/icons/Search';
import '../assets/css/components/search.css';
import { Image } from 'react-bootstrap';

export class Search extends Component {
    state = {
        data: SearchData,
        searchInput: '',
    };

    filterItems = (data, searchInput) => {
        if (!searchInput) {
            return data;
        }

        let newData = [];
        newData = data.filter((datum) => {
            return (
                datum.title.indexOf(searchInput) > -1 ||
                datum.url.indexOf(searchInput) > -1 ||
                datum.description.indexOf(searchInput) > -1
            );
        });

        return newData.length > 0 ? newData : [];
    };

    render() {
        // let filteredItems = this.filterItems(this.state.data, this.state.searchInput);

        return (
            <div className="advance-learning-search-content-container">
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Image fluid src="/images/headings/alex.jpg" />
                </div>
                <div className="search-bar-container">
                    <TextField
                        id="standard-basic"
                        label="Search"
                        variant="outlined"
                        className="search-bar"
                        onChange={(e) => {
                            this.setState({ searchInput: e.target.value });
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {this.state.searchInput && <div>This functionality is coming soon!</div>}

                {/* {filteredItems.map((datum, index) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                window.location.href = datum.url;
                            }}
                            className="search-content-wrapper"
                        >
                            <div className="search-title">
                                {datum.title} {datum.isPerlPick && <span>Perl's Pick</span>}
                            </div>
                            <p className="search-link">{datum.url}</p>
                            <p className="search-description">{datum.description}</p>
                        </div>
                    );
                })} */}
            </div>
        );
    }
}

export default Search;
