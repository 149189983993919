import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import ItemsCarousel from 'react-items-carousel';
import './ItemCarousel.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReactGA from 'react-ga';

const ItemCarousel = ({ data }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    const chevronStyles = {
        color: '#70b448',
        fontSize: 40,
    };
    return (
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={5}
                gutter={50}
                leftChevron={<ChevronLeftIcon style={chevronStyles} />}
                rightChevron={<ChevronRightIcon style={chevronStyles} />}
                outsideChevron
                chevronWidth={chevronWidth}
                infiniteLoop
            >
                {data.map((datum, index) => {
                    return datum ? (
                        <a
                            key={index}
                            className="app-link-container"
                            href={datum.app_url ? datum.app_url : ''}
                            target="_blank"
                            onClick={() => {
                                ReactGA.event({
                                    category: 'app_click',
                                    action: 'redirect',
                                    label: `opened ${datum.app_name}`,
                                });
                            }}
                        >
                            <div className="app-image-container">
                                <Image src={datum.thumbnail_url} fluid />
                            </div>
                            <div className="app-label">{datum.app_name}</div>
                        </a>
                    ) : null;
                })}
            </ItemsCarousel>
        </div>
    );
};

export default ItemCarousel;
