export const SearchData = [
    {
        title: 'Dictionary by Merriam-Webster',
        url: 'https://www.merriam-webster.com/',
        description: `The dictionary by Merriam-Webster is America's most trusted online dictionary for English word definitions, meanings, and pronunciation.`,
        isPerlPick: false,
    },
    {
        title: 'Math Playground',
        url: 'https://www.mathplayground.com/',
        description:
            'Math games and more at MathPlayground.com! Problem solving, games, and puzzles the entire family will enjoy.',
        isPerlPick: true,
    },
];
