import React, { Component } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Iframe from 'react-iframe';

export class Compas extends Component {
    state = {
        activeIndex: 0,
    };
    render() {
        const { activeIndex } = this.state;
        return (
            <div>
                <div style={{ marginBottom: 15, textAlign: 'center' }}>
                    <Image fluid src="/images/headings/compas.jpg" />
                </div>

                <Row>
                    <Col xs={2}>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 0 });
                            }}
                        >
                            AsEs: P1: WrUp
                        </div>
                        <div
                            className="category-select-container"
                            onClick={() => {
                                this.setState({ activeIndex: 1 });
                            }}
                        >
                            AsEs: P1: ConVo
                        </div>
                    </Col>
                    <Col xs={10} style={{ borderLeftWidth: 1, borderLeftColor: '#D3D3D3', borderLeftStyle: 'solid' }}>
                        {activeIndex == 0 && (
                            <Iframe
                                url="https://docs.google.com/forms/u/0/d/e/1FAIpQLSdufilqVkVqnAN9apC_CEBVpHmZ43CKK21s3mbrFWXTMVSwlg/formResponse?embedded=true"
                                width="100%"
                                height="1250px"
                                frameBorder="0"
                                id="myId"
                            ></Iframe>
                        )}
                        {activeIndex == 1 && (
                            <Iframe
                                url="https://docs.google.com/forms/u/0/d/e/1FAIpQLSf0F6S_Mdadpg3JT1bhKIEOe-z3wWchxGlg9IqZ9KUswzWCfQ/formResponse?embedded=true"
                                width="100%"
                                height="1250px"
                                frameBorder="0"
                                id="myId"
                            ></Iframe>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Compas;
