import React, { Component } from 'react';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../assets/css/components/notebook.css';

export class Notebook extends Component {
    state = {
        editorState: EditorState.createEmpty(),
    };

    onEditorStateChange = (editorState) => {
        this.setState(editorState);
    };

    render() {
        const { editorState } = this.state;

        return (
            <div className="notebook-content-container">
                <div className="notebook-content-wrapper">
                    <div className="notebook-subject-label">Subject: English</div>

                    <div className="notebook-editor-container">
                        <p className="notebook-editor-label">Questions</p>
                        <p>What questions do you have in mind?</p>
                        <div className="notebook-editor-wrapper">
                            <Editor
                                initialEditorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>
                    </div>

                    <div className="notebook-editor-container">
                        <p className="notebook-editor-label">Observations</p>
                        <p>Jot down your observations/learnings here</p>
                        <div className="notebook-editor-wrapper">
                            <Editor
                                initialEditorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>
                    </div>

                    <div className="notebook-editor-container">
                        <p className="notebook-editor-label">Connections</p>
                        <p>Connect the information you have learned.</p>
                        <div className="notebook-editor-wrapper">
                            <Editor
                                initialEditorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>
                    </div>

                    <div className="notebook-editor-container">
                        <p className="notebook-editor-label">Answers</p>
                        <p>If you have answers to your prior questions, write them here.</p>
                        <div className="notebook-editor-wrapper">
                            <Editor
                                initialEditorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>
                    </div>

                    <div className="notebook-editor-container">
                        <p className="notebook-editor-label">Applications</p>
                        <p>How can you apply or transfer this knowledge to real life?</p>
                        <div className="notebook-editor-wrapper">
                            <Editor
                                initialEditorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Notebook;
